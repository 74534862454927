<template>
  <div class="">
    <HeadNav />

    <div class="flex flex-col md:flex-row mb-10">
      <div
        class="
          w-full
          md:w-1/2
          flex
          items-center
          justify-center
          md:justify-start
        "
      >
        <img src="/hospital_management_system_in_Nigeria_schedule.png" alt="" class="w-full h-3/5" />
      </div>
      <div class="w-full md:w-1/2">
        <div class="schedule-card">
          <div class="flex-row">
            <div class="flex-1">
              <h2 class="text-3xl font-bold mt-10">Schedule a Demo</h2>
              <p class="block mt-2">Let’s Customize Your Demo</p>
            </div>

            <form class="justify-start">
              <div class="form-row">
                <label class=""> Full Name </label>
                <input type="text" class="form-control" />
              </div>

              <div class="form-row">
                <label class=""> Email </label>
                <input type="email" class="form-control" />
              </div>

              <div class="form-row">
                <label class=""> Phone Number </label>
                <input type="text" class="form-control" />
              </div>

              <div class="form-row">
                <label class=""> Company Name </label>
                <input type="text" class="form-control" />
              </div>

              <div class="form-row">
              <label class=""> Select Country</label>

                
                <country-select v-model="selectedCountry" placeholder="Select Country" class="w-full rounded  focus:ring-green-600 focus:border-green-600 " ></country-select>
                
              </div>

              <div class="form-row">
              <label class=""> Select state</label>
                <region-select v-model="selectedState" :country="selectedCountry" placeholder="Select State" class="w-full  rounded  focus:ring-green-600 focus:border-green-600">"></region-select>
              </div>


              

              <div class="form-control py-6">
                <button
                  class="
                    bg-primary
                    px-10
                    rounded-xl
                    text-md
                    font-medium
                    text-white
                    py-3
                  "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadNav from "@/views/components/HeadNav.vue";
import { CountrySelect, RegionSelect } from 'vue-country-region-select';

export default {
  components: {
    HeadNav,
    CountrySelect,
    RegionSelect
  },

  data() {
    return {
      selectedCountry: null,
      selectedState: null
    };
  }
};
</script>


