<template>
  <div id="app">
    <router-view />

    <FooterView />
  </div>
</template>

<script>
import FooterView from "@/views/components/FooterView.vue";
export default {
  components: {
    FooterView,
  },
};
</script>


<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  /* color: #2c3e50; */
}
div {
  @apply text-[#100F0F];
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* nav a.router-link-exact-active {
  color: #42b983;

} */

nav a.router-link-exact-active {
  @apply text-[#42b983] md:border-b-2 md:border-[#42b983];
}
</style>
