<template>
  <nav class="flex items-center border-b border-gray-300 border-1">
    <div class="basis-4/5 md:basis-1/5">
      <a href="" class="justify-start items-center">
        <img
          src="/SmartMedicare.png"
          alt=""
          class="ml-2.5 h-[14px] w-[206px]"
        />
      </a>
    </div>

    <ul
      class="
        flex-row
        justify-center
        w-full
        space-x-16
        items-center
        basis-4/5
        hidden
        md:flex
      "
      id="menu"
    >
      <li class="">
        <router-link to="/" class="route">Home</router-link>
      </li>
      <li class="">
        <router-link to="/schedule" class="route"> Schedule a Demo</router-link>
      </li>
      <li class="">
        <router-link to="/about" class="route"> About Us</router-link>
      </li>
      <li class="">
        <router-link to="/contact-us" class="route">Contact Us</router-link>
      </li>

      <div class="flex justify-end">
        <button
          class="
            px-12
            border border-green-600
            rounded-md
            h-[40px]
            hover:bg-primary hover:text-white hover:border-green-500
          "
        >
          Login
        </button>
      </div>
    </ul>

    <div class="px-4 cursor-pointer md:hidden" id="burger">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="toggleModal = !toggleModal"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </div>

    <!-- Mobile VIew Modal -->
    <div>
      <div
        v-if="toggleModal"
        class="
          fixed
          overflow-x-hidden overflow-y-auto
          flex
          justify-center
          items-start
          mt-10
          inset-0
          z-50
        "
      >
        <div class="relative mx-auto w-auto">
          <div
            class="
              bg-white
              w-full
              rounded
              p-4
              shadow-2xl
              transition
              ease-in-out
              delay-150
            "
          >
            <div class="flex justify-end">
              <button
                class="
                  btn
                  rounded
                  mt-1
                  text-black
                  font-bold
                  text-lg
                  bg-white
                  py-3
                  px-3
                "
                @click="toggleModal = false"
              >
                X
              </button>
            </div>
            <ul class="flex flex-col text-left w-[256px] space-y-3" id="menu">
              <li class="">
                <router-link to="/" class="route">Home</router-link>
              </li>
              <li class="">
                <router-link to="/schedule" class="route">
                  Schedule a Demo</router-link
                >
              </li>
              <li class="">
                <router-link to="/about" class="route"> About Us</router-link>
              </li>
              <li class="">
                <router-link to="/contact-us" class="route"
                  >Contact Us</router-link
                >
              </li>

              <div class="flex justify-center">
                <button
                  class="
                    mt-12
                    px-12
                    border border-green-600
                    rounded-md
                    h-[40px]
                    hover:bg-primary hover:text-white hover:border-green-500
                  "
                >
                  Login
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="toggleModal"
        class="absolute z-40 inset-0 opacity-25 bg-black"
      ></div>
    </div>
    <!--End of Mobile View Modal  -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      toggleModal: false,
    };
  },
  beforeMount() {},
  mounted() {
    // const burger = document.querySelector("#burger");
    // const menu = document.querySelector("#menu");
    // burger.addEventListener("click", () => {
    //   if (menu.classList.contains("hidden")) {
    //     menu.classList.remove("hidden");
    //   } else {
    //     menu.classList.add("hidden");
    //   }
    // });
  },
};
</script>

<style scoped>
/* nav a.router-link-exact-active  */

.route {
  @apply pb-2;
}
</style>