var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 md:gap-6 space-y-2"},[_c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('PatientMnt')],1),_c('h3',{staticClass:"section-h3"},[_vm._v("Patient Management")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" Patients' data are recorded in an e-database system. These are photos, test results, diagnosis, prognosis, drugs and administration are stored for authorized access only. Healthcare providers can sytematically access and share records in the treatment and care chain. ")])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_appointment.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Book An Appointment")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" Our system allows patients to book appointments before arrival. It reduces waiting time, doctors' overtime, and nurses can manage pre-clinic activities. It notifies patients of their scheduled appointments. Encourages fair treatment as class is not considered in booking. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_hr.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("HR Management & Access Control")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" Consider the possibility of handling health workers' biodata, employment data, attendance, working hours, and payroll. A system integrated into your HMS calculates performance, shift, rota, payments, and reimbursement. SmartMedicare gives you control over who has access to private information and places within the facility. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_finance.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Finance & Billing")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" It helps to sum up all the expenses of a patient at a time and produce a complete bill at the end of the consultation. This saves time and effort for each department, as they need not produce separate bills. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_emergency.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Emergency Services")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" The HMS supports ambulance and paramedics services records to aid emergency management. Associated services such as first responder and call services in emergency situations are also provided. Administrators, personnel and users of emergency services benefit from the system ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_inventory.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Inventory Management")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" SmartMedicare streamlines inventory operations, saving you time and money while improving patient care. The service facilitates the tracking of devices, consumables, drugs, and materials. Improve intra/interdepartmental communication, eliminate theft, meet regulatory compliance, and increase efficiency through better inventory management. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_ward.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Ward & Bed Management")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" A smart technology for beds and wards management. Save time with access to beds, track patients' records based on bed allocations, colour-code the beds for easy management. Get notifications when a bed is empty or occupied. With these, the wards and beds are better managed for quality service. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_blood.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Blood Bank & Haematology Services")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" This system aids the information of blood pints handling in the blood bank. Keeps record of in-patients, out-patients, and volunteers. Managers can key in the parameters of blood tests conducted on each pint received by the bank. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_lab.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Laboratory")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" Manage your lab by ensuring all doctor-ordered investigations or tests are sent directly to your lab. The system reduces human errors as data is stored in the system. Patients' history reports can be accessed and reviewed anytime. Real time. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_pharmacy.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Pharmacy Management")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" SmartMedicare automates your pharmacy and drugs dispensing workflow. Let's help your pharmacists review doctors' orders, prepare the drugs, control the stock, handle the billing, provide counselling services and other dispensing tasks.Go wit us to stave off medicine fraud, improve service delivery for a better patient care. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_stats.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Statistics")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" Get real-time patient data, and manage as required with the Statistics feature of our HMS. Cull up demographic information, medical history and treatment, healthcare providers record and any administrative information. Access data to aid your business survey and planning. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-card items-center"},[_c('div',{},[_c('div',{staticClass:"flex justify-center"},[_c('img',{attrs:{"src":"/hospital_management_system_in_Nigeria_identity.png","alt":""}})]),_c('h3',{staticClass:"section-h3 mb-4"},[_vm._v("Universal Medical Identity")]),_c('p',{staticClass:"mx-auto w-2/3"},[_vm._v(" The system generates a unique patient ID. With the ID, healthcare providers can better manage the patient. Patients' access to other facilities is well transitioned. ")])])])
}]

export { render, staticRenderFns }