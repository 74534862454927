var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('HeadNav'),_c('div',{staticClass:"flex flex-col md:flex-row mb-10"},[_vm._m(0),_c('div',{staticClass:"w-full md:w-1/2"},[_c('div',{staticClass:"schedule-card"},[_c('div',{staticClass:"flex-row"},[_vm._m(1),_c('form',{staticClass:"justify-start"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Select Country")]),_c('country-select',{staticClass:"w-full rounded focus:ring-green-600 focus:border-green-600",attrs:{"placeholder":"Select Country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Select state")]),_c('region-select',{staticClass:"w-full rounded focus:ring-green-600 focus:border-green-600",attrs:{"country":_vm.selectedCountry,"placeholder":"Select State"},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}},[_vm._v("\">")])],1),_vm._m(6)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full md:w-1/2 flex items-center justify-center md:justify-start"},[_c('img',{staticClass:"w-full h-3/5",attrs:{"src":"/hospital_management_system_in_Nigeria_schedule.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[_c('h2',{staticClass:"text-3xl font-bold mt-10"},[_vm._v("Schedule a Demo")]),_c('p',{staticClass:"block mt-2"},[_vm._v("Let’s Customize Your Demo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Full Name ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Email ")]),_c('input',{staticClass:"form-control",attrs:{"type":"email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Phone Number ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('label',{},[_vm._v(" Company Name ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control py-6"},[_c('button',{staticClass:"bg-primary px-10 rounded-xl text-md font-medium text-white py-3"},[_vm._v(" Submit ")])])
}]

export { render, staticRenderFns }