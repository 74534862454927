<template>
  <section>
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-6 space-y-2">
      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <PatientMnt />
          </div>
          <h3 class="section-h3">Patient Management</h3>
          <p class="mx-auto w-2/3">
            Patients' data are recorded in an e-database system. These are
            photos, test results, diagnosis, prognosis, drugs and administration
            are stored for authorized access only. Healthcare providers can
            sytematically access and share records in the treatment and care
            chain.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_appointment.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Book An Appointment</h3>
          <p class="mx-auto w-2/3">
            Our system allows patients to book appointments before arrival. It
            reduces waiting time, doctors' overtime, and nurses can manage
            pre-clinic activities. It notifies patients of their scheduled
            appointments. Encourages fair treatment as class is not considered
            in booking.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_hr.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">HR Management & Access Control</h3>
          <p class="mx-auto w-2/3">
            Consider the possibility of handling health workers' biodata,
            employment data, attendance, working hours, and payroll. A system
            integrated into your HMS calculates performance, shift, rota,
            payments, and reimbursement. SmartMedicare gives you control over
            who has access to private information and places within the
            facility.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_finance.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Finance & Billing</h3>
          <p class="mx-auto w-2/3">
            It helps to sum up all the expenses of a patient at a time and
            produce a complete bill at the end of the consultation. This saves
            time and effort for each department, as they need not produce
            separate bills.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_emergency.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Emergency Services</h3>
          <p class="mx-auto w-2/3">
            The HMS supports ambulance and paramedics services records to aid
            emergency management. Associated services such as first responder
            and call services in emergency situations are also provided.
            Administrators, personnel and users of emergency services benefit
            from the system
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_inventory.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Inventory Management</h3>
          <p class="mx-auto w-2/3">
            SmartMedicare streamlines inventory operations, saving you time and
            money while improving patient care. The service facilitates the
            tracking of devices, consumables, drugs, and materials. Improve
            intra/interdepartmental communication, eliminate theft, meet
            regulatory compliance, and increase efficiency through better
            inventory management.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_ward.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Ward & Bed Management</h3>
          <p class="mx-auto w-2/3">
            A smart technology for beds and wards management. Save time with
            access to beds, track patients' records based on bed allocations,
            colour-code the beds for easy management. Get notifications when a
            bed is empty or occupied. With these, the wards and beds are better
            managed for quality service.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_blood.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Blood Bank & Haematology Services</h3>
          <p class="mx-auto w-2/3">
            This system aids the information of blood pints handling in the
            blood bank. Keeps record of in-patients, out-patients, and
            volunteers. Managers can key in the parameters of blood tests
            conducted on each pint received by the bank.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_lab.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Laboratory</h3>
          <p class="mx-auto w-2/3">
            Manage your lab by ensuring all doctor-ordered investigations or
            tests are sent directly to your lab. The system reduces human errors
            as data is stored in the system. Patients' history reports can be
            accessed and reviewed anytime. Real time.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_pharmacy.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Pharmacy Management</h3>
          <p class="mx-auto w-2/3">
            SmartMedicare automates your pharmacy and drugs dispensing workflow.
            Let's help your pharmacists review doctors' orders, prepare the
            drugs, control the stock, handle the billing, provide counselling
            services and other dispensing tasks.Go wit us to stave off medicine
            fraud, improve service delivery for a better patient care.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_stats.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Statistics</h3>
          <p class="mx-auto w-2/3">
            Get real-time patient data, and manage as required with the
            Statistics feature of our HMS. Cull up demographic information,
            medical history and treatment, healthcare providers record and any
            administrative information. Access data to aid your business survey
            and planning.
          </p>
        </div>
      </div>

      <div class="feature-card items-center">
        <div class="">
          <div class="flex justify-center">
            <img src="/hospital_management_system_in_Nigeria_identity.png" alt="" />
          </div>
          <h3 class="section-h3 mb-4">Universal Medical Identity</h3>
          <p class="mx-auto w-2/3">
            The system generates a unique patient ID. With the ID, healthcare
            providers can better manage the patient. Patients' access to other
            facilities is well transitioned.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PatientMnt from "@/views/components/PatientMnt.vue";
export default {
  components: {
    PatientMnt,
  },
};
</script>