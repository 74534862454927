<template>
  <div class="">
    <HeadNav />

    <div class="flex flex-col mb-10">
      <div class="flex flex-col mt-10">
        <h2 class="text-xl md:text-3xl font-bold mx-8">Get In Touch With Us</h2>
        <p class="block mt-2 mx-8">
          Feel free to contact us. We do get back to you as soon as possible.
        </p>
        <div class="flex justify-center">
          <img
            src="/hospital_management_system_in_Nigeria_get_in_touch.png"
            class="w-[333px] md:w-[616px] h-[167px] md:h-[309px] mt-4"
          />
        </div>
      </div>
      <div class="mx-auto w-[90%] md:w-4/5">
        <div class="contact-card">
          <div class="">
            <form class="justify-start">
              <div class="grid grid-cols-2 gap-8">
                <div class="contact-input">
                  <label class=""> Full Name </label>
                  <input type="text" class="form-control" />
                </div>

                <div class="contact-input">
                  <label class=""> Email </label>
                  <input type="email" class="form-control" />
                </div>

                <div class="contact-input">
                  <label class=""> Phone Number </label>
                  <input type="text" class="form-control" />
                </div>

                <div class="contact-input">
                  <label class=""> Subject </label>
                  <input type="text" class="form-control" />
                </div>

                <div class="form-row col-span-2 mx-4">
                  <label class=""> Message </label>
                  <textarea
                    name=""
                    id=""
                    class="form-control w-full"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <div class="form-control py-6 mx-auto">
                <button
                  class="
                    bg-primary
                    w-[230px]
                    rounded-xl
                    text-md
                    font-medium
                    text-white
                    py-3
                  "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import HeadNav from "@/views/components/HeadNav.vue";

export default {
  components: {
    HeadNav,
  },
};
</script>
<style scoped>
.contact-input {
  @apply form-row col-span-2 md:col-span-1 mx-4;
}
</style>