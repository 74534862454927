<template>
  <div class="mx-auto font-sans">
    <HeadNav />

    <section class="flex flex-col-reverse md:flex-row items-center">
      <div class="w-full md:w-1/2">
        <div class="w-full md:w-4/5">
          <h2
            class="font-bold text-2xl md:text-4xl text-center md:text-left mx-8"
          >
            SmartMedicare - #1 Hospital Management System!!!
          </h2>
          <p class="mt-4 text-lg text-center md:text-left mx-8">
            Smart Medicare is an all-in-one hospital management system that
            enables you to run your core clinical, financial and operational
            processes from one place
          </p>
        </div>

        <div
          class="flex justify-center md:justify-start items-centers mt-10 pl-8"
        >
        <router-link to="/schedule">

       
          <button
            class="
              px-12
              py-4
              bg-primary
              rounded-md
              text-white
              font-bold
              hover:bg-white
              hover:text-green-600
              hover:border-green-500
              hover:border
            "
          >
            Request a Free Demo
          </button>

        </router-link>
        </div>
      </div>
      <div class="flex w-full md:w-1/2">
        <HeroSVG class="mt-16" />
      </div>
    </section>

    <div
      class="bg-no-repeat"
      style="
        background-image: url('/why_choose_us.png');
        background-size: 100% 100%;
      "
    >
      <div class="mt-8">
        <h2 class="text-center font-sans font-bold text-2xl mx-8">
          Why Choose Us
        </h2>
        <p class="mt-4 mx-8">
          Smart Medicare has been designed to reduce waiting time while
          enhancing
        </p>
        <p class="mx-8">
          patient care by providing hospital staff with accurate and timely
          patient information.
        </p>
        <p>
          This all-in-one hospital management system comes with integrated
          modules such as
        </p>
        <p>
          Appointment, Billing, Inventory, Pharmacy, Laboratory, Ambulance etc
        </p>
      </div>

      <div class="flex-row items-center hidden md:flex">
        <ChooseUs class="w-2/3 justify-end" />
        <div class="text-left items-center w-1/3 space-y-2">
          <p class="font-bold text-xl mb-2">Our Concerns</p>
          <li>Confidentiality & Compliance</li>
          <li>Access to Information and Records</li>
          <li>Records tracing Mechanisms</li>
          <li>Information Management</li>
        </div>
      </div>
    </div>

    <section class="">
      <div class="my-16">
        <h2 class="section-h2">Features</h2>
        <p class="mt-4 text-center">
          Improving hospital management by streamlining the processes
        </p>
        <!-- <p>of our customers are attend to.</p> -->
      </div>

      <FeatureList />
    </section>

    <div>
      <ReviewsView />
    </div>

    <div class="mb-24"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeadNav from "@/views/components/HeadNav.vue";
import HeroSVG from "@/views/components/HeroSVG.vue";
import ChooseUs from "@/views/components/ChooseUs.vue";
import FeatureList from "@/views/components/FeatureList.vue";
import ReviewsView from "@/views/components/ReviewsView.vue";

export default {
  components: {
    HeadNav,
    HeroSVG,
    ChooseUs,
    FeatureList,
    ReviewsView,
  },
};
</script>
<style scoped>
/* @screen xs {
  backgrounder {
    background-image: url(./assets/img/register_bg_2.png);
    background-size: 100%;
    @apply bg-no-repeat;
  }
} */
</style>
